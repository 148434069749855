<template>
  <div
    class="csn-lsm"
    :class="{ 'csn-lsm-displayed': SHOWS_PNP_ACCOUNT_SIDE_MENU }"
  >
    <div class="csn-lsm-header">
      <div />
      <NavbarLogoButton @click.native="handleCloseButtonClick" />
      <button
        @click="handleCloseButtonClick"
        class="csn-notification-menu-close-btn"
      >
        <CloseIcon />
      </button>
    </div>
    <span class="csn-lsm-register-title">{{ t('pay_and_play') }}</span>
    <PnpAccountContent @close="handleCloseClick" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  TOGGLE_PNP_ACCOUNT_SIDE_MENU,
  SHOWS_PNP_ACCOUNT_SIDE_MENU,
  Module,
  RouteName,
  PNP_ACCOUNT_MODAL,
} from '@/constants'
import { navigateTo, callFn } from '@/helpers'

export default {
  name: 'PnpSideMenu',
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    PnpAccountContent: () => import('@/components/PnpAccountContent'),
  },
  data: () => ({
    openNextSideMenu: null,
  }),
  computed: {
    ...mapState(Module.MAIN, [SHOWS_PNP_ACCOUNT_SIDE_MENU]),
    t() {
      return this.$createComponentTranslator(PNP_ACCOUNT_MODAL)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_PNP_ACCOUNT_SIDE_MENU]),
    handleCloseButtonClick() {
      this.TOGGLE_PNP_ACCOUNT_SIDE_MENU(false)
      navigateTo({ name: RouteName.HOME })
    },
    async handleCloseClick(fn) {
      await this.TOGGLE_PNP_ACCOUNT_SIDE_MENU(false)
      callFn(fn)
    },
  },
}
</script>
